<a class="list-item-link cbox-list-item open-chat w-100 {[ if (o.num_unread) { ]} unread-msgs {[ } ]}"
   title="{{{o.desc_chat}}}" href="#">

    <canvas class="avatar" height="30" width="30"></canvas>
    <span class="{{{o.status_icon}}}" title="{{{o.desc_status}}}"></span>
    {[ if (o.num_unread) { ]} <span class="msgs-indicator">{{{ o.num_unread }}}</span> {[ } ]}
    <span class="contact-name contact-name--{{{o.show}}} {[ if (o.num_unread) { ]} unread-msgs {[ } ]}">{{{o.display_name}}}</span>
</a>
{[ if (o.allow_contact_removal) { ]}
<a class="list-item-action remove-xmpp-contact far fa-trash-alt" title="{{{o.desc_remove}}}" href="#"></a>
{[ } ]}
