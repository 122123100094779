<div>
    <form id="converse-register" class="converse-form">
        <legend class="col-form-label">{{{o.__("Create your account")}}}</legend>

        <div class="form-group">
            <label>{{{o.__("Please enter the XMPP provider to register with:")}}}</label>
            <div class="form-errors hidden"></div>

            {[ if (o.default_domain) { ]}
                {{{o.default_domain}}}
            </div>
            {[ } else { ]}
                <input class="form-control" required="required" type="text" name="domain" placeholder="{{{o.domain_placeholder}}}"/>
                <p class="form-text text-muted">{{{o.help_providers}}} <a href="{{{o.href_providers}}}" class="url" target="_blank" rel="noopener">{{{o.help_providers_link}}}</a>.</p>
            </div>
            <fieldset class="buttons">
                <input class="btn btn-primary" type="submit" value="{{{o.label_register}}}"/>
                <div class="switch-form">
                    <p>{{{ o.__("Already have a chat account?") }}}</p>
                    <p><a class="login-here toggle-register-login" href="#converse/login">{{{o.__("Log in here")}}}</a></p>
                </div>
            </fieldset>
            {[ } ]}
        <!--</div>-->
    </form>
</div>
