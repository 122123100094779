{[ if (o.allow_chat_pending_contacts)  { ]}
<a class="open-chat w-100"href="#">
{[ } ]}
<span class="req-contact-name w-100" title="JID: {{{o.jid}}}">{{{o.display_name}}}</span>
{[ if (o.allow_chat_pending_contacts)  { ]}
</a>
{[ } ]}
<a class="accept-xmpp-request list-item-action list-item-action--visible fa fa-check"
   aria-label="{{{o.desc_accept}}}" title="{{{o.desc_accept}}}" href="#"></a>
<a class="decline-xmpp-request list-item-action list-item-action--visible  fa fa-times"
   aria-label="{{{o.desc_decline}}}" title="{{{o.desc_decline}}}" href="#"></a>
